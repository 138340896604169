import React from "react";

import * as Sentry from "@sentry/react";

import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import env from "../util/env";

Sentry.init({
  enabled: env?.SENTRY_ENABLED ?? true,
  dsn:
    env?.SENTRY_DSN ??
    "https://eaad0514f858922feacc3dfbf51778d2@o4505720001658880.ingest.sentry.io/4506772814299136",
  environment: env?.SENTRY_APP_ENVIRONMENT ?? "local",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
});

export default Sentry.withSentryReactRouterV6Routing(Routes);
