import React from "react";

export type FormHeadingProps = {
  children: React.ReactNode;
  multiline?: boolean;
};
export default function FormHeading({ children, multiline }: FormHeadingProps) {
  return (
    <div
      className={`flex py-4 ${
        multiline ? "flex-col" : "row justify-between items-center"
      }`}
    >
      {children}
    </div>
  );
}
