import React, { useCallback, useEffect, useState } from "react";

import { useNotificationContext } from "@tiller-ds/alert";
import { Button } from "@tiller-ds/core";
import { LoadingIcon } from "@tiller-ds/icons";

import { FormikProps } from "formik/dist/types";
import { useNavigate } from "react-router-dom";

import { addFavouriteProducts } from "../api/addFavouriteProducts";
import {
  registerRestaurant,
  ErrorResponse,
  RegisteredRestaurantResponse,
} from "../api/registerRestaurant";
import AddingFavouriteProductsSearch from "../components/addingFavouriteProductsForm/AddingFavouriteProductsSearch";
import PageContainer from "../components/common/PageContainer";
import PageTitle from "../components/common/PageTitle";
import RestaurantRegistrationPreferencesForm from "../components/restaurantPreferencesForm/RestaurantRegistrationPreferencesForm";
import RestaurantRegistrationForm from "../components/restaurantRegistrationForm/RestaurantRegistrationForm";
import { FavouriteProduct } from "../types/addingFavouriteProductsTypes";
import { RestaurantPreferencesFormType } from "../types/restaurantPreferencesTypes";
import { RegisterRestaurantFormType } from "../types/restaurantRegistrationTypes";
import { PHONE_NUMBER_PREFIX } from "../util/constants";
import {
  createErrorNotification,
  createSuccessNotification,
} from "../util/functions";

export default function RestaurantRegistrationPage() {
  const [
    restaurantRegistrationFormikRef,
    setRestaurantRegistrationFormikRef,
  ] = useState<React.RefObject<FormikProps<RegisterRestaurantFormType>>>();
  const [
    restaurantRegistrationPreferencesFormikRef,
    setRestaurantRegistrationPreferencesFormikRef,
  ] = useState<React.RefObject<FormikProps<RestaurantPreferencesFormType>>>();

  const [
    restaurantRegistrationFormValues,
    setRestaurantRegistrationFormValues,
  ] = useState<RegisterRestaurantFormType>({} as RegisterRestaurantFormType);
  const [
    restaurantPreferencesFormValues,
    setRestaurantPreferencesFormValues,
  ] = useState<RestaurantPreferencesFormType>({
    ...{ showPrices: true },
  } as RestaurantPreferencesFormType);

  const [
    favouriteProductsFormValues,
    setFavouriteProductsFormValues,
  ] = useState<FavouriteProduct[]>([]);

  const [isFinalForm, setIsFinalForm] = useState(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const [createdRestaurantId, setCreatedRestaurantId] = useState<number>();

  const { push } = useNotificationContext();

  const navigate = useNavigate();

  const handleErrorResponse = useCallback(
    (error: ErrorResponse) => {
      setSubmitted(false);
      switch (error.additionalInfo.notValidField) {
        case "username":
          push(
            createErrorNotification(
              "Korisnik s unesenim korisničkim imenom već postoji u sustavu"
            )
          );
          break;
        case "restaurant":
          push(
            createErrorNotification("Odabrani restoran već postoji u sustavu")
          );
          break;
      }
    },
    [push]
  );

  const handleAddFavouriteProductsResponse = useCallback(
    (response: Response) => {
      switch (response.status) {
        case 201:
          push(createSuccessNotification("Restoran je uspješno spremljen"));
          navigate("/");
          break;
        default:
          push(
            createErrorNotification(
              "Dogodila se pogreška tijekom dodavanja proizvoda"
            )
          );
      }
      setSubmitted(false);
    },
    [navigate, push]
  );

  useEffect(() => {
    if (createdRestaurantId && submitted) {
      if (favouriteProductsFormValues.length > 0) {
        addFavouriteProducts(
          createdRestaurantId,
          favouriteProductsFormValues.map((value) => {
            if (value.specialName?.trim()) {
              return {
                externalProductId: value.product.externalProductId,
                originalName: value.product.originalName,
                specialName: value.specialName?.trim(),
              };
            }
            return {
              externalProductId: value.product.externalProductId,
              originalName: value.product.originalName,
            };
          })
        ).then(handleAddFavouriteProductsResponse);
      } else {
        setSubmitted(false);
        push(createSuccessNotification("Restoran je uspješno spremljen"));
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createdRestaurantId,
    favouriteProductsFormValues,
    handleAddFavouriteProductsResponse,
    navigate,
    submitted,
  ]);

  const handleRegisterRestaurantResponse = useCallback(
    (response: Response) => {
      switch (response.status) {
        case 201:
          response
            .json()
            .then((body) =>
              setCreatedRestaurantId((body as RegisteredRestaurantResponse).id)
            );
          break;
        case 409:
          response.json().then((body) => handleErrorResponse(body));
          break;
        default:
          push(
            createErrorNotification(
              "Dogodila se pogreška tijekom registracije restorana"
            )
          );
          setSubmitted(false);
      }
    },
    [handleErrorResponse, push]
  );

  useEffect(() => {
    if (submitted) {
      const registerRestaurantRequest = {
        restaurant: {
          ...restaurantRegistrationFormValues.restaurant,
          address: restaurantRegistrationFormValues.restaurantAddress.trim(),
          hidePrices: !restaurantPreferencesFormValues.showPrices,
          paymentMethod: restaurantPreferencesFormValues.paymentMethod,
        },
        client: restaurantRegistrationFormValues.client,
        warehouse: restaurantRegistrationFormValues.warehouse,
        userAccount: {
          username: restaurantRegistrationFormValues.username
            .trim()
            .toLowerCase(),
          password: restaurantRegistrationFormValues.password,
        },
        headOfSupply: {
          firstName: restaurantRegistrationFormValues.firstName.trim(),
          lastName: restaurantRegistrationFormValues.lastName.trim(),
          contactNumber: `${PHONE_NUMBER_PREFIX} ${restaurantRegistrationFormValues.contactNumber.trim()}`,
        },
      };
      registerRestaurant(registerRestaurantRequest).then(
        handleRegisterRestaurantResponse
      );
    }
  }, [
    handleRegisterRestaurantResponse,
    restaurantPreferencesFormValues,
    restaurantRegistrationFormValues,
    submitted,
  ]);

  return (
    <PageContainer>
      <PageTitle onBack={() => navigate("/")}>
        Kreiranje novog restorana
      </PageTitle>
      {!isFinalForm ? (
        <RestaurantRegistrationForm
          setFormikRef={setRestaurantRegistrationFormikRef}
          initialValues={restaurantRegistrationFormValues}
          setFormValues={setRestaurantRegistrationFormValues}
          setIsFinalForm={setIsFinalForm}
        />
      ) : (
        <div className="grid gap-y-4">
          <AddingFavouriteProductsSearch
            initialFavouriteProducts={favouriteProductsFormValues}
            setFavouriteProductsFormValues={setFavouriteProductsFormValues}
          />
          <RestaurantRegistrationPreferencesForm
            setFormikRef={setRestaurantRegistrationPreferencesFormikRef}
            initialValues={restaurantPreferencesFormValues}
            setRestaurantPreferencesForm={setRestaurantPreferencesFormValues}
            setSubmitted={setSubmitted}
          />
        </div>
      )}
      <div className="flex justify-between py-4">
        <Button
          className="gap-2"
          variant="text"
          onClick={() => {
            if (!isFinalForm) {
              navigate("/");
            } else {
              setIsFinalForm(false);
            }
          }}
        >
          {!isFinalForm ? "Odustani" : "Prethodni korak"}
        </Button>
        <Button
          className="gap-2"
          onClick={() => {
            if (!isFinalForm) {
              restaurantRegistrationFormikRef?.current?.submitForm();
            } else {
              restaurantRegistrationPreferencesFormikRef?.current?.submitForm();
            }
          }}
          type="submit"
          leadingIcon={submitted && <LoadingIcon />}
        >
          {!isFinalForm ? "Sljedeći korak" : "Spremi"}
        </Button>
      </div>
    </PageContainer>
  );
}
