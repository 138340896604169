import React from "react";

import PageContainer from "../components/common/PageContainer";
import RestaurantListTable from "../components/restaurantList/RestuarantListTable";

export default function RestaurantListPage() {
  return (
    <PageContainer>
      <RestaurantListTable />
    </PageContainer>
  );
}
