import React, { Dispatch, SetStateAction, useState } from "react";

import { Button, Card, IconButton, Pagination } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Input } from "@tiller-ds/form-elements";
import { DataTableField } from "@tiller-ds/formik-elements";
import { Icon } from "@tiller-ds/icons";
import { Autocomplete } from "@tiller-ds/selectors";

import { CurrentFavouriteProduct } from "./formValidators";
import { useMobileView } from "../../hooks/useMobileView";
import {
  FavouriteProduct,
  Product,
} from "../../types/addingFavouriteProductsTypes";
import { MAX_AUTOCOMPLETE_ITEM_LENGTH_MOBILE_VIEW } from "../../util/constants";
import TableFormBodyContainer from "../common/TableFormBodyContainer";

export type FavouriteProductsTableProps = {
  tableContent: FavouriteProduct[];
  onDeleteRowClick: (index: number) => void;
  autocompleteOptions: Product[];
  currentFavouriteProduct: CurrentFavouriteProduct | undefined;
  autocompleteError: string | undefined;
  onSpecialNameInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onAutocompleteChange: (product: undefined | Product | Product[]) => void;
  onAutocompleteReset: () => void;
  specialNameError: string | undefined;
  onResetCurrentProduct: () => void;
  onAddCurrentProduct: () => void;
  searching: () => boolean;
  pageNumber: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
  pageSize: number;
  numberOfItems: number;
};
export default function FavouriteProductsTable({
  tableContent,
  onDeleteRowClick,
  autocompleteOptions,
  currentFavouriteProduct,
  autocompleteError,
  onSpecialNameInputChange,
  onAutocompleteChange,
  onAutocompleteReset,
  specialNameError,
  onResetCurrentProduct,
  onAddCurrentProduct,
  searching,
  pageNumber,
  setPageNumber,
  numberOfItems,
  pageSize,
}: FavouriteProductsTableProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  const autocompleteItemFormattedForMobileView = (item: string) => (
    <>
      {item.slice(0, MAX_AUTOCOMPLETE_ITEM_LENGTH_MOBILE_VIEW).trim()}
      <br />
      {item.slice(MAX_AUTOCOMPLETE_ITEM_LENGTH_MOBILE_VIEW).trim()}
    </>
  );

  return (
    <TableFormBodyContainer>
      <Card>
        <Card.Body removeSpacing={true}>
          <DataTable data={tableContent}>
            <DataTable.Column
              className="w-24 max-w-24 md:min-w-80 md:w-80 lg:min-w-80 lg:w-80"
              canSort={false}
              header="Proizvod"
              accessor="product.originalName"
              title="Proizvod"
            >
              {(_, index) => {
                return <>{tableContent[index].product.originalName}</>;
              }}
            </DataTable.Column>
            <DataTable.Column
              className="w-24 max-w-24 md:min-w-64 md:w-64 lg:min-w-64 lg:w-64 break-all"
              canSort={false}
              header="Alias"
              accessor="specialName"
              title="Alias"
            >
              {(_, index) => <>{tableContent[index].specialName || "-"}</>}
            </DataTable.Column>
            <DataTable.Column
              id="actions"
              canSort={false}
              className="w-2 min-w-2 max-w-2"
            >
              {(_, index) => (
                <IconButton
                  icon={
                    <Icon
                      type="trash"
                      variant="fill"
                      className="text-gray-500"
                    />
                  }
                  label="Obriši"
                  onClick={() => onDeleteRowClick(index)}
                />
              )}
            </DataTable.Column>
          </DataTable>
          <DataTableField.NewRow>
            <div
              className={`grid ${
                mobileView ? "gap-4 p-2" : "grid-cols-12 gap-8 p-4"
              }`}
            >
              <div className="col-span-4">
                <Autocomplete
                  label="Proizvod"
                  name="productSelect"
                  options={autocompleteOptions}
                  value={currentFavouriteProduct?.product}
                  getOptionLabel={(product) => {
                    if (
                      mobileView &&
                      product.originalName.length >
                        MAX_AUTOCOMPLETE_ITEM_LENGTH_MOBILE_VIEW
                    ) {
                      return autocompleteItemFormattedForMobileView(
                        product.originalName
                      );
                    }
                    return product.originalName;
                  }}
                  getOptionValue={(product) => product.externalProductId}
                  itemToString={(product) => product.originalName}
                  filter={(input: string, product) =>
                    product.originalName
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  onChange={onAutocompleteChange}
                  onReset={onAutocompleteReset}
                  error={autocompleteError}
                />
              </div>
              <div className="col-span-4">
                <Input
                  name="specialNameInputField"
                  label="Alias"
                  crossOrigin={undefined}
                  value={currentFavouriteProduct?.specialName || ""}
                  onChange={onSpecialNameInputChange}
                  error={specialNameError}
                />
              </div>
              <div
                className={`col-span-4 ${
                  mobileView ? "pt-2 flex justify-around" : "self-end"
                }`}
              >
                <Button
                  type="button"
                  variant="text"
                  onClick={onResetCurrentProduct}
                >
                  Očisti
                </Button>
                <Button type="button" onClick={onAddCurrentProduct}>
                  Dodaj proizvod
                </Button>
              </div>
            </div>
          </DataTableField.NewRow>
        </Card.Body>
      </Card>
      {!searching() && (
        <Pagination
          onPageChange={(event) => setPageNumber(event)}
          pageNumber={pageNumber}
          pageSize={pageSize}
          totalElements={numberOfItems}
        >
          {() => <></>}
        </Pagination>
      )}
    </TableFormBodyContainer>
  );
}
