import React from "react";

import { NotificationProps } from "@tiller-ds/alert";
import { Icon } from "@tiller-ds/icons";

export const createErrorNotification = (
  text: string,
  timeout?: number
): NotificationProps => ({
  icon: <Icon type="warning" size={8} className="text-warning-700" />,
  title: text,
  timeout: timeout,
});

export const createSuccessNotification = (text: string): NotificationProps => ({
  icon: <Icon type="check-circle" size={5} className="text-success-700" />,
  title: text,
});
