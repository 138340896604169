import React, { useState } from "react";

import { IconButton, Typography } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useMobileView } from "../../hooks/useMobileView";

type PageTitleProps = {
  children: React.ReactNode;
  onBack?: () => void;
  actions?: React.ReactNode;
};
export default function PageTitle({
  children,
  onBack,
  actions,
}: PageTitleProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  return (
    <div
      className={`flex ${
        mobileView ? "flex-col" : "flex-row justify-between items-center"
      }`}
    >
      <div className="flex pb-6">
        <IconButton
          icon={
            <Icon className="text-blue-600" type="caret-left" variant="bold" />
          }
          onClick={onBack}
          showTooltip={false}
        />
        <Typography variant="h4">{children}</Typography>
      </div>
      {actions}
    </div>
  );
}
