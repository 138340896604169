import React, { useState } from "react";

import PartnerAutocompleteField from "./PartnerAutocompleteField";
import { useMobileView } from "../../hooks/useMobileView";
import { Partner } from "../../types/restaurantRegistrationTypes";
import LabeledField from "../common/LabeledField";

type LabeledInputFieldProps = {
  label: string;
  fieldName: string;
  partners: Partner[];
};
export default function LabeledPartnerAutocompleteField({
  label,
  partners,
  fieldName,
}: LabeledInputFieldProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  const partnerAutocompleteField = (
    <PartnerAutocompleteField
      name={fieldName}
      partners={partners}
      label={mobileView ? label : ""}
    />
  );
  return (
    <>
      {mobileView ? (
        partnerAutocompleteField
      ) : (
        <LabeledField label={label}>{partnerAutocompleteField}</LabeledField>
      )}
    </>
  );
}
