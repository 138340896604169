import { Dispatch, useEffect } from "react";

import { useNotificationContext } from "@tiller-ds/alert";

import { getPartnersByType } from "../api/getPartnersByType";
import { Partner, PartnerType } from "../types/restaurantRegistrationTypes";
import { createErrorNotification } from "../util/functions";

export default function useRestaurantRegistrationRequiredData(
  setClients: Dispatch<Partner[]>,
  setRestaurants: Dispatch<Partner[]>,
  setWarehouses: Dispatch<Partner[]>
) {
  const { push } = useNotificationContext();

  const pushFetchingPartnersErrorNotification = (
    whileFetchingFormatted: String
  ) =>
    push(
      createErrorNotification(
        `Došlo je do greške tijekom dohvata ${whileFetchingFormatted}`
      )
    );

  useEffect(() => {
    getPartnersByType(PartnerType.CLIENT).then(
      (clients) => {
        if (clients.length > 1) {
          setClients(clients);
        } else {
          pushFetchingPartnersErrorNotification("klijenata");
        }
      },
      () => pushFetchingPartnersErrorNotification("klijenata")
    );
    getPartnersByType(PartnerType.SHIPMENT_LOCATION).then(
      (restaurants) => {
        if (restaurants.length > 1) {
          setRestaurants(restaurants);
        } else {
          pushFetchingPartnersErrorNotification("restorana");
        }
      },
      () => pushFetchingPartnersErrorNotification("restorana")
    );
    getPartnersByType(PartnerType.WAREHOUSE).then(
      (warehouses) => {
        if (warehouses.length > 1) {
          setWarehouses(warehouses);
        } else {
          pushFetchingPartnersErrorNotification("skladišta");
        }
      },
      () => pushFetchingPartnersErrorNotification("skladišta")
    );
    /* eslint-disable */
  }, [setClients, setRestaurants, setWarehouses]);
}
