import React, { useState } from "react";

import { AutocompleteField } from "@tiller-ds/formik-elements";

import { useMobileView } from "../../hooks/useMobileView";
import { Partner } from "../../types/restaurantRegistrationTypes";

type PartnerAutocompleteFieldProps = {
  partners: Partner[];
  name: string;
  label?: string;
};
export default function PartnerAutocompleteField({
  partners,
  name,
  label,
}: PartnerAutocompleteFieldProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  return (
    <AutocompleteField
      name={name}
      label={label}
      className={mobileView ? "col-span-full" : "col-span-4"}
      options={partners}
      getOptionLabel={(partner) => partner.name}
      getOptionValue={(partner) => partner.id}
      itemToString={(partner) => partner.name}
      filter={(input: string, partner) =>
        partner.name.toLowerCase().includes(input.toLowerCase())
      }
      sendOptionValue={false}
    />
  );
}
