import * as yup from "yup";

const usernameValidationRegex = /^[ -~čćđšžČĆĐŠŽ]+$/;
const passwordValidationRegex = /^[ -~čćđšžČĆĐŠŽ]+$/;
const nameValidationRegex = /^[A-Za-zčćđšžČĆĐŠŽ' -]+$/;
const addressValidationRegex = /^[A-Za-zčćđšžČĆĐŠŽ, 0-9'-]+$/;
const phoneNumberValidationRegex = /^9[0-9] [0-9]{3} [0-9]{3,4}$/;

const maxLastNameLength = 50;
const maxFirstNameLength = 30;
const maxAddressLength = 100;

export const maxCharacterCountMessage = (count: number) =>
  "Moguće je unijeti najviše " + count + " znakova";

export const notValidSpecialNameRegex = /[^A-Za-zčćđšžČĆĐŠŽ0-9 -]+/;

const requiredFieldValidation = yup.string().required("Polje obavezno za unos");

const usernameValidation = yup
  .string()
  .required("Korisničko ime je obvezno")
  .max(30, "Korisničko ime ne smije sadržavati više od 30 znakova")
  .matches(
    usernameValidationRegex,
    "Korisničko ime sadrži nedozvoljene znakove"
  );
const passwordValidation = yup
  .string()
  .required("Unesite lozinku")
  .min(6, "Lozinka je prekratka - potrebno je barem 6 znakova")
  .max(30, "Lozinka ne smije sadržavati više od 30 znakova")
  .matches(passwordValidationRegex, "Lozinka sadrži nedozvoljene znakove");
const confirmPasswordValidation = yup
  .string()
  .required("Ponovite lozinku")
  .oneOf([yup.ref("password")], "Lozinke se ne podudaraju");

const clientValidation = yup.object().required("Odaberite klijenta");
const restaurantValidation = yup.object().required("Odaberite restoran");
const restaurantAddressValidation = yup
  .string()
  .required("Unesite adresu restorana")
  .matches(addressValidationRegex, "Adresa sadrži nedozvoljene znakove")
  .max(maxAddressLength, maxCharacterCountMessage(maxAddressLength));
const warehouseValidation = yup.object().required("Odaberite skladište");

const firstNameValidation = yup
  .string()
  .required("Unesite ime")
  .max(maxFirstNameLength, maxCharacterCountMessage(maxFirstNameLength))
  .matches(nameValidationRegex, "Ime sadrži nedozvoljene znakove");
const lastNameValidation = yup
  .string()
  .required("Unesite prezime")
  .max(maxLastNameLength, maxCharacterCountMessage(maxLastNameLength))
  .matches(nameValidationRegex, "Prezime sadrži nedozvoljene znakove");
const contactNumberValidation = yup
  .string()
  .required("Unesite kontakt broj")
  .matches(phoneNumberValidationRegex, "Unesite broj u formatu 9X XXX XXX(X)");

const paymentMethodValidation = yup
  .string()
  .required("Odaberite način plaćanja");

export const USERNAME_PASSWORD_VALIDATION = yup.object({
  username: requiredFieldValidation,
  password: requiredFieldValidation,
});

export const RESTAURANT_REGISTRATION_VALIDATION_SCHEMA = yup.object().shape({
  username: usernameValidation,
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation,
  client: clientValidation,
  restaurant: restaurantValidation,
  restaurantAddress: restaurantAddressValidation,
  warehouse: warehouseValidation,
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  contactNumber: contactNumberValidation,
});

export const RESTAURANT_UPDATE_VALIDATION_SCHEMA = yup.object().shape({
  warehouse: warehouseValidation,
  firstName: firstNameValidation,
  lastName: lastNameValidation,
  contactNumber: contactNumberValidation,
});

export const PAYMENT_METHOD_VALIDATION_SCHEMA = yup.object().shape({
  paymentMethod: paymentMethodValidation,
});
