import React, { useEffect, useState } from "react";

import { Button, Card, Pagination } from "@tiller-ds/core";
import { DataTable } from "@tiller-ds/data-display";
import { Input } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import useRestaurants from "../../hooks/restaurantListHooks";
import { useMobileView } from "../../hooks/useMobileView";
import { Restaurant } from "../../types/restaurantListTypes";
import { validateSearchInput } from "../addingFavouriteProductsForm/formValidators";
import FormContainer from "../common/FormContainer";
import FormHeading from "../common/FormHeading";
import FormTitle from "../common/FormTitle";
import TableFormBodyContainer from "../common/TableFormBodyContainer";

const PAGE_SIZE = 10;
export default function RestaurantListTable() {
  const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
  const [tableContent, setTableContent] = useState<Restaurant[]>(restaurants);
  const [pageNumber, setPageNumber] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchInputError, setSearchInputError] = useState<string>();

  useEffect(() => {
    if (searchValue) {
      setTableContent(
        restaurants.filter((restaurant) =>
          restaurant.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else {
      setTableContent(restaurants);
    }
  }, [restaurants, searchValue]);

  useMobileView(setMobileView);

  const navigate = useNavigate();

  useEffect(() => {
    // paging
    setTableContent(
      restaurants.slice(
        pageNumber * PAGE_SIZE,
        pageNumber * PAGE_SIZE + PAGE_SIZE
      )
    );
  }, [restaurants, pageNumber]);

  useRestaurants(setRestaurants);

  const formActions = (
    <>
      <Input
        className="w-full md:w-96 lg:w-96 py-2 lg:pr-2 md:pr-2"
        inlineLeadingIcon={<Icon type="magnifying-glass" />}
        name="searchBar"
        onChange={(event) => {
          const searchValue = event.target.value;
          if (validateSearchInput(searchValue.length, setSearchInputError)) {
            setSearchValue(searchValue);
          }
        }}
        crossOrigin={undefined}
        value={searchValue}
        placeholder="Pretraži po nazivu"
        error={searchInputError}
      />
      <Button
        size={mobileView ? "xs" : "md"}
        onClick={() => navigate("/restaurant/register")}
      >
        Dodaj restoran
      </Button>
    </>
  );

  return (
    <FormContainer>
      <FormHeading multiline={mobileView}>
        <FormTitle>Popis restorana</FormTitle>
        {mobileView ? (
          formActions
        ) : (
          <div className="flex flex-row items-center">{formActions}</div>
        )}
      </FormHeading>
      <TableFormBodyContainer>
        <Card>
          <Card.Body removeSpacing={true}>
            <DataTable
              data={tableContent}
              onClick={(restaurant) => {
                navigate(`/restaurant/${restaurant.id}`);
              }}
            >
              <DataTable.Column
                canSort={false}
                header="Restoran"
                accessor="name"
                title="Naziv restorana"
                className="max-w-sm"
              />
              <DataTable.Column
                canSort={false}
                header="Voditelj nabave"
                accessor="headOfSupply.name"
                title="Voditelj nabave"
                className="max-w-sm"
              />
              <DataTable.Column
                canSort={false}
                header="Adresa"
                accessor="address"
                title="Adresa restorana"
                className="max-w-sm"
              />
              <DataTable.Column
                canSort={false}
                header="Kontakt broj"
                accessor="headOfSupply.contactNumber"
                title="Kontakt broj voditelja nabave"
                className="max-w-sm"
              />
            </DataTable>
          </Card.Body>
        </Card>
        {!searchValue && (
          <Pagination
            onPageChange={(event) => setPageNumber(event)}
            pageNumber={pageNumber}
            pageSize={PAGE_SIZE}
            totalElements={restaurants.length}
          >
            {() => <></>}
          </Pagination>
        )}
      </TableFormBodyContainer>
    </FormContainer>
  );
}
