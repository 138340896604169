import React, { useCallback, useEffect, useState } from "react";

import FavouriteProductsTable from "./FavouriteProductsTable";
import {
  useFavouriteProductCallbackFunctions,
  useFilterNotAddedFavouriteProducts,
  usePaging,
  useProductCodebook,
  useSetContent,
} from "../../hooks/favouriteProductsHooks";
import {
  FavouriteProduct,
  Product,
} from "../../types/addingFavouriteProductsTypes";

type CurrentFavouriteProduct = {
  product?: Product;
  specialName?: string;
};

const PAGE_SIZE = 10;

export type AddingFavouriteProductsFormProps = {
  setFavouriteProductsFormValues: (
    favouriteProducts: FavouriteProduct[]
  ) => void;
  initialFavouriteProducts: FavouriteProduct[];
  resetSearch: () => void;
  searchValue: string | undefined;
};
export default function AddingFavouriteProductsForm({
  setFavouriteProductsFormValues,
  initialFavouriteProducts,
  resetSearch,
  searchValue,
}: AddingFavouriteProductsFormProps) {
  const [favouriteProducts, setFavouriteProducts] = useState(
    initialFavouriteProducts
  );
  const [tableContent, setTableContent] = useState(favouriteProducts);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentFavouriteProduct, setCurrentFavouriteProduct] = useState<
    CurrentFavouriteProduct
  >();
  const [autocompleteError, setAutocompleteError] = useState<string>();
  const [specialNameError, setSpecialNameError] = useState<string>();
  const [products, setProducts] = useState<Product[]>([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState(products);

  useProductCodebook(setProducts);

  const {
    validateFields,
    onAutocompleteReset,
    onAutocompleteChange,
    onResetCurrentProduct,
    goToLastPage,
    filterFavouriteProducts,
    searching,
    onSpecialNameInputChange,
  } = useFavouriteProductCallbackFunctions(
    setAutocompleteError,
    setSpecialNameError,
    setCurrentFavouriteProduct,
    autocompleteOptions,
    setPageNumber,
    favouriteProducts?.length,
    PAGE_SIZE,
    searchValue
  );

  useSetContent(
    searchValue,
    favouriteProducts,
    setTableContent,
    filterFavouriteProducts,
    pageNumber,
    PAGE_SIZE
  );

  usePaging(pageNumber, setPageNumber, PAGE_SIZE, favouriteProducts?.length);

  useFilterNotAddedFavouriteProducts(
    products,
    setAutocompleteOptions,
    favouriteProducts
  );

  useEffect(() => {
    setFavouriteProductsFormValues(favouriteProducts);
  }, [favouriteProducts, setFavouriteProductsFormValues]);

  const onDeleteRowClick = useCallback(
    (index: number) => {
      resetSearch();
      const product = tableContent[index];
      setFavouriteProducts((old) =>
        old.filter(
          (item) =>
            item.product.externalProductId !== product.product.externalProductId
        )
      );
    },
    [resetSearch, tableContent]
  );

  const onAddCurrentProduct = useCallback(() => {
    resetSearch();
    if (validateFields(currentFavouriteProduct) && currentFavouriteProduct) {
      setFavouriteProducts((old) => [
        ...old,
        {
          product: currentFavouriteProduct.product as Product,
          specialName: currentFavouriteProduct.specialName?.trim() || "",
        },
      ]);
      goToLastPage();
    }
    setCurrentFavouriteProduct(undefined);
  }, [currentFavouriteProduct, goToLastPage, resetSearch, validateFields]);

  return (
    <FavouriteProductsTable
      tableContent={tableContent}
      onDeleteRowClick={onDeleteRowClick}
      autocompleteOptions={autocompleteOptions}
      currentFavouriteProduct={currentFavouriteProduct}
      autocompleteError={autocompleteError}
      onSpecialNameInputChange={onSpecialNameInputChange}
      onAutocompleteChange={onAutocompleteChange}
      onAutocompleteReset={onAutocompleteReset}
      specialNameError={specialNameError}
      onResetCurrentProduct={onResetCurrentProduct}
      onAddCurrentProduct={onAddCurrentProduct}
      searching={searching}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageSize={PAGE_SIZE}
      numberOfItems={favouriteProducts.length}
    />
  );
}
