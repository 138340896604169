import * as React from "react";

import { Button, Card } from "@tiller-ds/core";
import { FormLayout, Label } from "@tiller-ds/form-elements";
import { InputField, PasswordInputField } from "@tiller-ds/formik-elements";
import { LoadingIcon } from "@tiller-ds/icons";

import { Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";

import logo from "../../assets/img/marikomerc-logo.svg";
import { USERNAME_PASSWORD_VALIDATION } from "../../validation/validationSchema";

const initialValues = {
  username: "",
  password: "",
};

type LoginProps = {
  children: React.ReactNode;
  handleSubmit: (values: any) => void;
  formikRef: React.RefObject<FormikProps<any>>;
};
export default function LoginForm({
  handleSubmit,
  children,
  formikRef,
}: LoginProps) {
  return (
    <div className="max-w-lg mx-auto">
      <Card>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={USERNAME_PASSWORD_VALIDATION}
            onSubmit={handleSubmit}
            validateAfterSubmit={true}
            innerRef={formikRef}
          >
            {(formik) => {
              return (
                <Form placeholder={undefined}>
                  <FormLayout.Section>
                    <div className="flex flex-col py-2 px-4 md:py-4 md:px-8">
                      <div className="max-w-lg mx-auto py-8">
                        <img src={logo} alt="logo" className="w-32" />
                      </div>
                      <h1 className="mb-4 text-3xl font-normal">
                        Prijava u sustav
                      </h1>
                      <Label className="mb-1 text-base font-normal">
                        Korisničko ime
                      </Label>
                      <InputField
                        className="mb-4 text-base font-normal"
                        name="username"
                        autoFocus={true}
                      />
                      <Label className="mb-1 text-base font-normal">
                        Lozinka
                      </Label>
                      <PasswordInputField
                        className="mb-4 text-base font-normal"
                        name="password"
                      />
                      {children}
                      <Button
                        leadingIcon={formik.isSubmitting && <LoadingIcon />}
                        className="flex flex-row w-full"
                        variant="filled"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        Prijava
                      </Button>
                    </div>
                  </FormLayout.Section>
                </Form>
              );
            }}
          </Formik>
        </Card.Body>
      </Card>
    </div>
  );
}
