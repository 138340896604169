import React, { Dispatch, useEffect, useRef, useState } from "react";

import { FormLayout } from "@tiller-ds/form-elements";
import { RadioGroupField, ToggleField } from "@tiller-ds/formik-elements";

import { Formik } from "formik";
import { FormikProps } from "formik/dist/types";

import { useMobileView } from "../../hooks/useMobileView";
import { RestaurantPreferencesFormType } from "../../types/restaurantPreferencesTypes";
import { PAYMENT_METHOD_VALIDATION_SCHEMA } from "../../validation/validationSchema";
import FormContainer from "../common/FormContainer";
import FormTitle from "../common/FormTitle";

export type RestaurantRegistrationPreferencesFormProps = {
  setFormikRef: Dispatch<
    React.RefObject<FormikProps<RestaurantPreferencesFormType>>
  >;
  initialValues: RestaurantPreferencesFormType;
  setRestaurantPreferencesForm: Dispatch<RestaurantPreferencesFormType>;
  setSubmitted: Dispatch<boolean>;
};
export default function RestaurantRegistrationPreferencesForm({
  setFormikRef,
  initialValues,
  setRestaurantPreferencesForm,
  setSubmitted,
}: RestaurantRegistrationPreferencesFormProps) {
  const ref = useRef<FormikProps<RestaurantPreferencesFormType>>(null);
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  useEffect(() => {
    if (setFormikRef && ref) {
      setFormikRef(ref);
    }
  }, [setFormikRef, ref]);

  const onSubmit = (values: RestaurantPreferencesFormType) => {
    setRestaurantPreferencesForm({
      ...values,
    });
    setSubmitted(true);
  };

  const preferencesSegment = () => (
    <>
      <FormTitle>Postavke</FormTitle>
      <div className="justify-start py-3">
        <ToggleField
          className="text-sm font-normal"
          name="showPrices"
          label={<p>Prikaži cijene artikala na mobilnoj aplikaciji</p>}
        />
      </div>
      <RadioGroupField
        className="text-sm font-medium"
        label="Preferirani način plaćanja"
        name="paymentMethod"
        vertical={mobileView}
      >
        <RadioGroupField.Item label="Gotovinsko" value="CASH" />
        <RadioGroupField.Item label="Kartično" value="CARD" />
        <RadioGroupField.Item label="Virmansko" value="BANK_TRANSFER" />
      </RadioGroupField>
    </>
  );

  return (
    <FormContainer>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={PAYMENT_METHOD_VALIDATION_SCHEMA}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
            onBlur={() => {
              setRestaurantPreferencesForm(formik.values);
            }}
          >
            <FormLayout>{preferencesSegment()}</FormLayout>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}
