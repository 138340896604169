import React, { Dispatch, SetStateAction, useState } from "react";

import { Input } from "@tiller-ds/form-elements";
import { Icon } from "@tiller-ds/icons";

import AddingFavouriteProductsForm from "./AddingFavouriteProductsForm";
import { validateSearchInput } from "./formValidators";
import { useMobileView } from "../../hooks/useMobileView";
import { FavouriteProduct } from "../../types/addingFavouriteProductsTypes";
import FormContainer from "../common/FormContainer";
import FormHeading from "../common/FormHeading";
import FormTitle from "../common/FormTitle";

type AddFavouriteProductsSearchableTableFormProps = {
  initialFavouriteProducts: FavouriteProduct[];
  setFavouriteProductsFormValues: Dispatch<SetStateAction<FavouriteProduct[]>>;
};
export default function AddingFavouriteProductsSearch(
  props: AddFavouriteProductsSearchableTableFormProps
) {
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchInputError, setSearchInputError] = useState<string>();
  const [mobileView, setMobileView] = useState(false);

  useMobileView(setMobileView);

  return (
    <FormContainer>
      <FormHeading multiline={mobileView}>
        <FormTitle className={mobileView ? "pb-6" : ""}>
          Ugovoreni proizvodi
        </FormTitle>
        <Input
          className="w-full md:w-96 lg:w-96"
          inlineLeadingIcon={<Icon type="magnifying-glass" />}
          name="searchBar"
          onChange={(event) => {
            const searchValue = event.target.value;
            if (validateSearchInput(searchValue.length, setSearchInputError)) {
              setSearchValue(searchValue);
            }
          }}
          crossOrigin={undefined}
          value={searchValue}
          placeholder="Pretraži po nazivu ili aliasu"
          error={searchInputError}
        />
      </FormHeading>
      <AddingFavouriteProductsForm
        {...props}
        resetSearch={() => setSearchValue("")}
        searchValue={searchValue}
      />
    </FormContainer>
  );
}
