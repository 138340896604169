export enum PartnerType {
  CLIENT = "CLIENT",
  SHIPMENT_LOCATION = "SHIPMENT_LOCATION",
  WAREHOUSE = "WAREHOUSE",
}

export type Partner = {
  id: string;
  name: string;
};

export type RegisterRestaurantFormType = {
  username: string;
  password: string;
  confirmPassword: string;
  client: Partner;
  restaurant: Partner;
  restaurantAddress: string;
  warehouse: Partner;
  firstName: string;
  lastName: string;
  contactNumber: string;
};
