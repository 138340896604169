import { PartnerRequest } from "./registerRestaurant";
import { PaymentMethod } from "../types/restaurantPreferencesTypes";
import { API_PREFIX } from "../util/constants";

export type UpdateRestaurantPreferences = {
  hidePrices?: boolean;
  paymentMethod?: PaymentMethod;
};

export type UpdateRestaurantPreferencesRequest = {
  preferences: UpdateRestaurantPreferences;
};

export type UpdateRestaurantRequest = {
  warehouse: PartnerRequest;
  headOfSupply: {
    firstName: string;
    lastName: string;
    contactNumber: string;
  };
};

export async function updateRestaurant(
  restaurantId: number,
  request: UpdateRestaurantRequest | UpdateRestaurantPreferencesRequest
): Promise<Response> {
  return await fetch(`${API_PREFIX}/restaurant/${restaurantId}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
