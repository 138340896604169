import { API_PREFIX } from "../util/constants";

export async function deleteFavouriteProduct(
  restaurantId: number,
  favouriteProductId: number
): Promise<Response> {
  return await fetch(
    `${API_PREFIX}/restaurant/${restaurantId}/product/${favouriteProductId}`,
    {
      method: "DELETE",
    }
  );
}
