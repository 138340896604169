import { Dispatch, SetStateAction, useEffect } from "react";

import { useNotificationContext } from "@tiller-ds/alert";

import { getPartnersByType } from "../api/getPartnersByType";
import { getRestaurant } from "../api/getRestaurant";
import { UpdateRestaurantFormType } from "../components/restaurantEditForm/UpdateRestaurantForm";
import { PageTitleType } from "../types/common";
import { RestaurantPreferencesFormType } from "../types/restaurantPreferencesTypes";
import { Partner, PartnerType } from "../types/restaurantRegistrationTypes";
import { UpdatingFavouriteProduct } from "../types/updatingFavouriteProductsTypes";
import { createErrorNotification } from "../util/functions";

export function useGetRestaurant(
  restaurantId: number,
  setRestaurantDetails: Dispatch<SetStateAction<PageTitleType | undefined>>,
  setFavouriteProducts: Dispatch<SetStateAction<UpdatingFavouriteProduct[]>>,
  setRestaurantPreferences: Dispatch<
    SetStateAction<RestaurantPreferencesFormType | undefined>
  >
) {
  const { push } = useNotificationContext();

  useEffect(() => {
    getRestaurant(restaurantId).then((response) => {
      if (response.status !== 200) {
        push(
          createErrorNotification(
            "Dogodila se pogreška tijekom dohvata postavki restorana. Pokušajte ponovno."
          )
        );
      } else {
        response.json().then((body) => {
          setRestaurantDetails({
            title: body.name,
            subtitle: body.address,
          });
          setFavouriteProducts(body.favouriteProducts);
          setRestaurantPreferences({
            paymentMethod: body.paymentMethod,
            showPrices: !body.hidePrices,
          });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restaurantId,
    setFavouriteProducts,
    setRestaurantDetails,
    setRestaurantPreferences,
  ]);
}

export function useGetWarehouses(
  setWarehouses: Dispatch<SetStateAction<Partner[]>>
) {
  const { push } = useNotificationContext();

  useEffect(() => {
    getPartnersByType(PartnerType.WAREHOUSE).then(
      (warehouses) => {
        if (warehouses.length > 1) {
          setWarehouses(warehouses);
        }
      },
      () =>
        push(
          createErrorNotification(
            "Dogodila se pogreška tijekom dohvata skladišta"
          )
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setWarehouses]);
}

export function useGetRestaurantDetails(
  restaurantId: number,
  setRestaurantDetails: Dispatch<SetStateAction<UpdateRestaurantFormType>>,
  setRestaurantBasicInfo: Dispatch<SetStateAction<PageTitleType | undefined>>
) {
  const { push } = useNotificationContext();

  useEffect(() => {
    getRestaurant(restaurantId).then((response) => {
      if (response.status !== 200) {
        push(
          createErrorNotification(
            "Dogodila se pogreška tijekom dohvata podataka o restoranu. Pokušajte ponovno."
          )
        );
      } else {
        response.json().then((body) => {
          setRestaurantDetails({
            restaurant: body.name,
            warehouse: body.warehouse,
            contactNumber: body.headOfSupply.phoneNumber.startsWith("+385 ")
              ? body.headOfSupply.phoneNumber.slice(5)
              : body.headOfSupply.phoneNumber,
            firstName: body.headOfSupply.firstName,
            lastName: body.headOfSupply.lastName,
          });
          setRestaurantBasicInfo({ title: body.name, subtitle: body.address });
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, setRestaurantDetails]);
}
