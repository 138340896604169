import fetchIntercept from "fetch-intercept";

import { LOGIN_PATH, REDIRECT_TO_QUERY_PARAM } from "../util/constants";

export default function responseErrorInterceptor() {
  fetchIntercept.register({
    response: function (response) {
      if (
        (response.status === 401 || response.status === 403) &&
        window.location.pathname !== LOGIN_PATH
      ) {
        window.location.replace(
          `${LOGIN_PATH}?${REDIRECT_TO_QUERY_PARAM}=${window.location.pathname}`
        );
      }
      return response;
    },
  });
}
