import fetchIntercept from "fetch-intercept";

import {
  API_PREFIX,
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  TOKEN_PREFIX,
} from "../util/constants";

const getAccessTokenFromStorage = () => {
  return localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
};

export default function accessTokenInterceptor() {
  fetchIntercept.register({
    request: function (url: string, config: any) {
      const accessToken = getAccessTokenFromStorage();

      if (accessToken && !url.endsWith(`${API_PREFIX}/login`)) {
        const authorizationHeader = {
          Authorization: TOKEN_PREFIX + accessToken,
        };
        if (config.headers) {
          config.headers = { ...config.headers, ...authorizationHeader };
        } else {
          config.headers = { ...authorizationHeader };
        }
      }

      return [url, config];
    },
  });
}
