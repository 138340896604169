import React from "react";

export type TableFormBodyContainerProps = {
  children: React.ReactNode;
};
export default function TableFormBodyContainer({
  children,
}: TableFormBodyContainerProps) {
  return <div className="space-y-4">{children}</div>;
}
