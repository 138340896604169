import React from "react";

import { Modal } from "@tiller-ds/alert";
import { UseModal } from "@tiller-ds/alert/Modal";
import { Button, Typography } from "@tiller-ds/core";

type ModalDeleteProps = {
  modal: UseModal<unknown>;
  title: string;
  content: string;
  buttonConfirmText: string;
  buttonConfirmFunction: () => void;
};

export default function DeleteModal({
  modal,
  title,
  content,
  buttonConfirmText,
  buttonConfirmFunction,
}: ModalDeleteProps) {
  return (
    <Modal {...modal}>
      <Modal.Content
        title={
          <Typography element="h3" variant="h4">
            {title}
          </Typography>
        }
      >
        <Typography element="p" variant="subtext" className="pt-2">
          {content}
        </Typography>
      </Modal.Content>
      <Modal.Footer>
        <Button variant="filled" onClick={buttonConfirmFunction}>
          {buttonConfirmText}
        </Button>
        <Button variant="outlined" color="white" onClick={modal.onClose}>
          Odustani
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
