import { Product } from "../types/addingFavouriteProductsTypes";
import { API_PREFIX } from "../util/constants";

export async function getProductCodebook(): Promise<Product[]> {
  const response = await fetch(`${API_PREFIX}/product/codebook`, {
    method: "GET",
  });
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(response.status);
}
