import React from "react";

import { Typography } from "@tiller-ds/core";

type LabeledFieldProps = {
  children: React.ReactNode;
  label: string;
};
export default function LabeledField({ children, label }: LabeledFieldProps) {
  return (
    <>
      <Typography className="font-medium">{label}</Typography>
      {children}
    </>
  );
}
