import React from "react";

type FormSectionTitleProps = {
  children: React.ReactNode;
  title: string;
};
export default function FormSection({
  children,
  title,
}: FormSectionTitleProps) {
  return (
    <>
      <h1 className="bg-slate-50 rounded .gap-4 px-2 py-3 font-bold">
        {title}
      </h1>
      <div className="grid grid-cols-5 gap-4 items-center">{children}</div>
    </>
  );
}
