import React, { useCallback, useEffect, useState } from "react";

import { useNotificationContext } from "@tiller-ds/alert";

import FavouriteProductsTable from "./FavouriteProductsTable";
import { CurrentFavouriteProduct } from "./formValidators";
import { addFavouriteProduct } from "../../api/addFavouriteProduct";
import { deleteFavouriteProduct } from "../../api/deleteFavouriteProduct";
import {
  useFavouriteProductCallbackFunctions,
  useFilterNotAddedFavouriteProducts,
  usePaging,
  useProductCodebook,
  useSetContent,
} from "../../hooks/favouriteProductsHooks";
import { Product } from "../../types/addingFavouriteProductsTypes";
import { UpdatingFavouriteProduct } from "../../types/updatingFavouriteProductsTypes";
import { createErrorNotification } from "../../util/functions";

const PAGE_SIZE = 10;

export type UpdateFavouriteProductsFormProps = {
  initialFavouriteProducts: UpdatingFavouriteProduct[];
  resetSearch: () => void;
  searchValue: string | undefined;
  restaurantId: number;
};
export default function UpdateFavouriteProductsForm({
  initialFavouriteProducts,
  resetSearch,
  searchValue,
  restaurantId,
}: UpdateFavouriteProductsFormProps) {
  const [favouriteProducts, setFavouriteProducts] = useState(
    initialFavouriteProducts
  );
  const [tableContent, setTableContent] = useState(favouriteProducts);
  const [pageNumber, setPageNumber] = useState(0);
  const [currentFavouriteProduct, setCurrentFavouriteProduct] = useState<
    CurrentFavouriteProduct
  >();
  const [autocompleteError, setAutocompleteError] = useState<string>();
  const [specialNameError, setSpecialNameError] = useState<string>();
  const [products, setProducts] = useState<Product[]>([]);
  useProductCodebook(setProducts);
  const [autocompleteOptions, setAutocompleteOptions] = useState(products);

  useEffect(() => {
    setFavouriteProducts(initialFavouriteProducts);
  }, [initialFavouriteProducts]);

  const { push } = useNotificationContext();

  const {
    validateFields,
    onAutocompleteReset,
    onAutocompleteChange,
    onResetCurrentProduct,
    goToLastPage,
    filterFavouriteProducts,
    searching,
    onSpecialNameInputChange,
  } = useFavouriteProductCallbackFunctions(
    setAutocompleteError,
    setSpecialNameError,
    setCurrentFavouriteProduct,
    autocompleteOptions,
    setPageNumber,
    favouriteProducts?.length,
    PAGE_SIZE,
    searchValue
  );

  useSetContent(
    searchValue,
    favouriteProducts,
    setTableContent,
    filterFavouriteProducts,
    pageNumber,
    PAGE_SIZE
  );

  usePaging(pageNumber, setPageNumber, PAGE_SIZE, favouriteProducts?.length);

  useFilterNotAddedFavouriteProducts(
    products,
    setAutocompleteOptions,
    favouriteProducts
  );

  const onDeleteRowClick = useCallback(
    (index: number) => {
      resetSearch();
      const product = tableContent[index];
      deleteFavouriteProduct(restaurantId, product.id).then((response) => {
        if (response.status !== 200) {
          push(
            createErrorNotification(
              "Dogodila se greška tijekom brisanja proizvoda. Pokušajte ponovno."
            )
          );
        } else {
          setFavouriteProducts((old) =>
            old.filter(
              (item) =>
                item.product.externalProductId !==
                product.product.externalProductId
            )
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resetSearch, restaurantId, tableContent]
  );

  const onAddCurrentProduct = useCallback(() => {
    resetSearch();
    if (validateFields(currentFavouriteProduct) && currentFavouriteProduct) {
      addFavouriteProduct(
        restaurantId,
        currentFavouriteProduct?.specialName?.trim()
          ? {
              externalProductId: currentFavouriteProduct?.product
                ?.externalProductId as string,
              originalName: currentFavouriteProduct?.product
                ?.originalName as string,
              specialName: currentFavouriteProduct?.specialName?.trim(),
            }
          : {
              externalProductId: currentFavouriteProduct?.product
                ?.externalProductId as string,
              originalName: currentFavouriteProduct?.product
                ?.originalName as string,
            }
      ).then((response) => {
        if (response.status !== 201) {
          push(
            createErrorNotification(
              "Dogodila se greška tijekom dodavanja proizvoda. Pokušajte ponovno."
            )
          );
        } else {
          response.json().then((body) => {
            goToLastPage();
            setFavouriteProducts((old) => [
              ...old,
              {
                id: body.id,
                product: currentFavouriteProduct.product as Product,
                specialName: currentFavouriteProduct.specialName?.trim() || "-",
              },
            ]);
          });
        }
      });
    }
    setCurrentFavouriteProduct(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentFavouriteProduct,
    goToLastPage,
    resetSearch,
    restaurantId,
    validateFields,
  ]);

  return (
    <FavouriteProductsTable
      tableContent={tableContent}
      onDeleteRowClick={onDeleteRowClick}
      autocompleteOptions={autocompleteOptions}
      currentFavouriteProduct={currentFavouriteProduct}
      autocompleteError={autocompleteError}
      onSpecialNameInputChange={onSpecialNameInputChange}
      onAutocompleteChange={onAutocompleteChange}
      onAutocompleteReset={onAutocompleteReset}
      specialNameError={specialNameError}
      onResetCurrentProduct={onResetCurrentProduct}
      onAddCurrentProduct={onAddCurrentProduct}
      searching={searching}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
      pageSize={PAGE_SIZE}
      numberOfItems={favouriteProducts.length}
    />
  );
}
