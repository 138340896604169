import React from "react";

import { NotificationProvider } from "@tiller-ds/alert";

import { BrowserRouter, Navigate, Route } from "react-router-dom";

import Sidebar from "./components/sidebar/Sidebar";
import SentryRouter from "./config/SentryRouter";
import LoginPage from "./pages/LoginPage";
import RestaurantEditPage from "./pages/RestaurantEditPage";
import RestaurantListPage from "./pages/RestaurantListPage";
import RestaurantOverviewPage from "./pages/RestaurantOverviewPage";
import RestaurantRegistrationPage from "./pages/RestaurantRegistrationPage";
import { LOGIN_PATH } from "./util/constants";

function App() {
  const redirectToRestaurantPath = <Navigate replace to="/restaurant" />;

  return (
    <NotificationProvider position="topLeft">
      <BrowserRouter>
        <Sidebar>
          <SentryRouter>
            <Route path="/" element={redirectToRestaurantPath} />
            <Route path="/restaurant" element={<RestaurantListPage />} />
            <Route
              path="/restaurant/:restaurantId"
              element={<RestaurantOverviewPage />}
            />
            <Route
              path="/restaurant/:restaurantId/edit"
              element={<RestaurantEditPage />}
            />
            <Route
              path="/restaurant/register"
              element={<RestaurantRegistrationPage />}
            />
            <Route path={LOGIN_PATH} element={<LoginPage />} />
            <Route path="*" element={redirectToRestaurantPath} />
          </SentryRouter>
        </Sidebar>
      </BrowserRouter>
    </NotificationProvider>
  );
}

export default App;
