import { Partner, PartnerType } from "../types/restaurantRegistrationTypes";
import { API_PREFIX } from "../util/constants";

export async function getPartnersByType(
  partnerType: PartnerType
): Promise<Partner[]> {
  const response = await fetch(
    `${API_PREFIX}/partner?partnerType=${partnerType}`,
    {
      method: "GET",
    }
  );
  if (response.ok) {
    return response.json();
  }
  return Promise.reject(response.status);
}
