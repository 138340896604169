import { Dispatch, SetStateAction, useEffect } from "react";

import { MAX_SMALL_SCREEN_SIZE } from "../util/constants";

export function useMobileView(
  setMobileView: Dispatch<SetStateAction<boolean>>
) {
  useEffect(() => {
    const handleScreenSize = () =>
      setMobileView(window.innerWidth < MAX_SMALL_SCREEN_SIZE);

    window.addEventListener("resize", handleScreenSize);
    handleScreenSize();
  }, [setMobileView]);
}
