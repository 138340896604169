import React, { Dispatch, useEffect, useRef } from "react";

import "../../App.css";

import { useNotificationContext } from "@tiller-ds/alert";

import { Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import { useNavigate } from "react-router-dom";

import { updateRestaurant } from "../../api/updateRestaurant";
import { Partner } from "../../types/restaurantRegistrationTypes";
import { PHONE_NUMBER_PREFIX } from "../../util/constants";
import {
  createErrorNotification,
  createSuccessNotification,
} from "../../util/functions";
import { RESTAURANT_UPDATE_VALIDATION_SCHEMA } from "../../validation/validationSchema";
import FormContainer from "../common/FormContainer";
import FormSection from "../common/FormSection";
import LabeledInputField from "../common/LabeledInputField";
import LabeledPartnerAutocompleteField from "../restaurantRegistrationForm/LabeledPartnerAutocompleteField";

export type UpdateRestaurantFormType = {
  restaurant: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  warehouse: Partner;
};

type UpdateRestaurantFormProps = {
  setFormikRef: Dispatch<
    React.RefObject<FormikProps<UpdateRestaurantFormType>>
  >;
  restaurantId: number;
  initialValues: UpdateRestaurantFormType;
  warehouses: Partner[];
};
export default function UpdateRestaurantForm({
  setFormikRef,
  restaurantId,
  initialValues,
  warehouses,
}: UpdateRestaurantFormProps) {
  const ref = useRef<FormikProps<UpdateRestaurantFormType>>(null);
  const navigate = useNavigate();

  const { push } = useNotificationContext();

  useEffect(() => {
    if (setFormikRef && ref) {
      setFormikRef(ref);
    }
  }, [setFormikRef, ref]);

  const onSubmit = (values: UpdateRestaurantFormType) => {
    if (restaurantId) {
      updateRestaurant(restaurantId, {
        headOfSupply: {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
          contactNumber: `${PHONE_NUMBER_PREFIX} ${values.contactNumber}`,
        },
        warehouse: values.warehouse,
      }).then((response) => {
        if (response.status !== 200) {
          push(
            createErrorNotification(
              "Dogodila se greška tijekom ažuriranja podataka. Pokušajte ponovno."
            )
          );
        } else {
          push(createSuccessNotification("Podatci uspješno ažurirani"));
          navigate(`/restaurant/${restaurantId}`);
        }
      });
    }
  };

  const restaurantSegment = () => (
    <FormSection title="Podatci o restoranu">
      <LabeledInputField
        disabled
        fieldName="restaurant"
        label="Naziv restorana"
      />
      <LabeledPartnerAutocompleteField
        fieldName="warehouse"
        partners={warehouses}
        label="Skladište"
      />
    </FormSection>
  );

  const headOfSupplySegment = () => (
    <FormSection title="Podatci o voditelju">
      <LabeledInputField fieldName="firstName" label="Ime" />
      <LabeledInputField fieldName="lastName" label="Prezime" />
      <LabeledInputField
        addOn={PHONE_NUMBER_PREFIX}
        fieldName="contactNumber"
        label="Kontakt broj"
      />
    </FormSection>
  );

  return (
    <FormContainer>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
        validationSchema={RESTAURANT_UPDATE_VALIDATION_SCHEMA}
        enableReinitialize
        innerRef={ref}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <>
              {restaurantSegment()}
              {headOfSupplySegment()}
            </>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}
