import { Dispatch, SetStateAction, useEffect } from "react";

import { useNotificationContext } from "@tiller-ds/alert";

import { getRestaurants } from "../api/getRestaurants";
import { Restaurant } from "../types/restaurantListTypes";
import { createErrorNotification } from "../util/functions";

export default function useRestaurants(
  setRestaurants: Dispatch<SetStateAction<Restaurant[]>>
) {
  const { push } = useNotificationContext();

  useEffect(() => {
    getRestaurants().then((response) => {
      if (response.status === 200) {
        response.json().then((body) => setRestaurants(body));
      } else {
        push(
          createErrorNotification(
            "Dogodila se pogreška tijekom dohvata restorana. Pokušajte ponovno."
          )
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRestaurants]);
}
