import { API_PREFIX } from "../util/constants";

export type LoginRequest = {
  username: string;
  password: string;
};

export async function login(request: LoginRequest): Promise<Response> {
  return await fetch(`${API_PREFIX}/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
