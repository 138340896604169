import React, { Dispatch, useEffect, useRef, useState } from "react";

import { FormLayout } from "@tiller-ds/form-elements";

import "../../App.css";

import { Formik } from "formik";
import { FormikProps } from "formik/dist/types";

import LabeledPartnerAutocompleteField from "./LabeledPartnerAutocompleteField";
import useRestaurantRegistrationRequiredData from "../../hooks/restaurantRegistrationHooks";
import {
  Partner,
  RegisterRestaurantFormType,
} from "../../types/restaurantRegistrationTypes";
import { PHONE_NUMBER_PREFIX } from "../../util/constants";
import { RESTAURANT_REGISTRATION_VALIDATION_SCHEMA } from "../../validation/validationSchema";
import FormContainer from "../common/FormContainer";
import FormSection from "../common/FormSection";
import LabeledInputField from "../common/LabeledInputField";
import LabeledPasswordField from "../common/LabeledPasswordField";

export type RestaurantRegistrationFormProps = {
  setFormikRef: Dispatch<
    React.RefObject<FormikProps<RegisterRestaurantFormType>>
  >;
  initialValues: RegisterRestaurantFormType;
  setFormValues: Dispatch<RegisterRestaurantFormType>;
  setIsFinalForm: Dispatch<boolean>;
};

export default function RestaurantRegistrationForm({
  setFormikRef,
  initialValues,
  setFormValues,
  setIsFinalForm,
}: RestaurantRegistrationFormProps) {
  const [clients, setClients] = useState<Partner[]>([]);
  const [restaurants, setRestaurants] = useState<Partner[]>([]);
  const [warehouses, setWarehouses] = useState<Partner[]>([]);

  const ref = useRef<FormikProps<RegisterRestaurantFormType>>(null);

  useRestaurantRegistrationRequiredData(
    setClients,
    setRestaurants,
    setWarehouses
  );

  useEffect(() => {
    if (setFormikRef && ref) {
      setFormikRef(ref);
    }
  }, [setFormikRef, ref]);

  const onSubmit = (values: RegisterRestaurantFormType) => {
    setFormValues({ ...values });
    setIsFinalForm(true);
  };

  const userAccountSegment = () => (
    <FormSection title="Korisnički račun">
      <LabeledInputField fieldName="username" label="Korisničko ime" />
      <LabeledPasswordField fieldName="password" label="Lozinka" />
      <LabeledPasswordField
        fieldName="confirmPassword"
        label="Ponovi lozinku"
      />
    </FormSection>
  );

  const restaurantSegment = () => (
    <FormSection title="Podatci o restoranu">
      <LabeledPartnerAutocompleteField
        fieldName="client"
        partners={clients}
        label="Naziv tvrtke/obrta"
      />
      <LabeledPartnerAutocompleteField
        fieldName="restaurant"
        partners={restaurants}
        label="Naziv restorana"
      />
      <LabeledInputField fieldName="restaurantAddress" label="Adresa" />
      <LabeledPartnerAutocompleteField
        fieldName="warehouse"
        partners={warehouses}
        label="Skladište"
      />
    </FormSection>
  );

  const headOfSupplySegment = () => (
    <FormSection title="Podatci o voditelju">
      <LabeledInputField fieldName="firstName" label="Ime" />
      <LabeledInputField fieldName="lastName" label="Prezime" />
      <LabeledInputField
        addOn={PHONE_NUMBER_PREFIX}
        fieldName="contactNumber"
        label="Kontakt broj"
      />
    </FormSection>
  );

  return (
    <FormContainer>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={onSubmit}
        innerRef={ref}
        validationSchema={RESTAURANT_REGISTRATION_VALIDATION_SCHEMA}
      >
        {(formik) => (
          <form
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            className="flex flex-col gap-2"
          >
            <FormLayout>
              <>
                {userAccountSegment()}
                {restaurantSegment()}
                {headOfSupplySegment()}
              </>
            </FormLayout>
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}
