export type ReactEnvironmentVariable = {
  SENTRY_APP_ENVIRONMENT: string;
  SENTRY_ENABLED: boolean;
  SENTRY_DSN: string;
};

interface ReactWindow extends Window {
  REACT_ENV: ReactEnvironmentVariable;
}

declare let window: ReactWindow;
const env = window.REACT_ENV || {};

export default env;
