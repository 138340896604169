import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";

import { useNotificationContext } from "@tiller-ds/alert";

import { getProductCodebook } from "../api/getProductCodebook";
import {
  CurrentFavouriteProduct,
  validateCurrentProduct,
  validateCurrentSpecialName,
} from "../components/addingFavouriteProductsForm/formValidators";
import {
  FavouriteProduct,
  Product,
} from "../types/addingFavouriteProductsTypes";
import { REQUIRED_FIELD_ERROR } from "../util/constants";
import { createErrorNotification } from "../util/functions";

export function useProductCodebook(setProducts: Dispatch<Product[]>) {
  const { push } = useNotificationContext();

  useEffect(() => {
    getProductCodebook().then(
      (products) => setProducts(products),
      () =>
        push(
          createErrorNotification(
            "Došlo je do greške tijekom dohvata kataloga. Pokušajte ponovno."
          )
        )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setProducts]);
}

export function usePaging(
  pageNumber: number,
  setPageNumber: Dispatch<SetStateAction<number>>,
  pageSize: number,
  numberOfItems: number
) {
  useEffect(() => {
    // if item is deleted and pageNumber is showing page without items
    // set page number to latest page with items
    if (pageNumber * pageSize + 1 > numberOfItems) {
      const newPageNumber = Math.floor(numberOfItems / (pageSize + 1));
      setPageNumber(newPageNumber < 0 ? 0 : newPageNumber);
    }
  }, [numberOfItems, pageNumber, pageSize, setPageNumber]);
}

export function useFavouriteProductCallbackFunctions(
  setAutocompleteError: Dispatch<SetStateAction<string | undefined>>,
  setSpecialNameError: Dispatch<SetStateAction<string | undefined>>,
  setCurrentFavouriteProduct: Dispatch<
    SetStateAction<CurrentFavouriteProduct | undefined>
  >,
  autocompleteOptions: Product[],
  setPageNumber: Dispatch<SetStateAction<number>>,
  numberOfItems: number,
  pageSize: number,
  searchValue: string | undefined
) {
  const validateFields = useCallback(
    (currentFavouriteProduct?: CurrentFavouriteProduct) => {
      if (
        currentFavouriteProduct &&
        validateCurrentProduct(
          currentFavouriteProduct.product,
          setAutocompleteError
        ) &&
        validateCurrentSpecialName(
          setSpecialNameError,
          currentFavouriteProduct.specialName
        )
      ) {
        return true;
      } else {
        setAutocompleteError(REQUIRED_FIELD_ERROR);
        return false;
      }
    },
    [setAutocompleteError, setSpecialNameError]
  );

  const onAutocompleteChange = useCallback(
    (product: undefined | Product | Product[]) => {
      setAutocompleteError(undefined);
      if (product && typeof product === "object" && !Array.isArray(product)) {
        const foundProduct = autocompleteOptions.filter(
          (item) =>
            item.externalProductId === (product as Product).externalProductId
        )[0];
        setCurrentFavouriteProduct((old) => ({
          ...old,
          product: foundProduct,
        }));
      } else {
        setCurrentFavouriteProduct((old) => ({
          ...old,
          product: undefined,
        }));
      }
    },
    [autocompleteOptions, setAutocompleteError, setCurrentFavouriteProduct]
  );

  const onAutocompleteReset = useCallback(() => {
    setAutocompleteError(undefined);
    setCurrentFavouriteProduct((old) => ({
      ...old,
      product: undefined,
    }));
  }, [setAutocompleteError, setCurrentFavouriteProduct]);

  const onResetCurrentProduct = useCallback(
    () => setCurrentFavouriteProduct(undefined),
    [setCurrentFavouriteProduct]
  );

  const goToLastPage = useCallback(() => {
    setPageNumber(Math.floor(numberOfItems / pageSize));
  }, [numberOfItems, pageSize, setPageNumber]);

  const filterFavouriteProducts = useCallback(
    (item: FavouriteProduct): boolean => {
      const searchString = searchValue?.toLowerCase() || "";
      return (
        item.product.originalName.toLowerCase().includes(searchString) ||
        ((item?.specialName &&
          item.specialName.toLowerCase().includes(searchString)) as boolean)
      );
    },
    [searchValue]
  );

  const searching = useCallback(() => !!searchValue, [searchValue]);

  const onSpecialNameInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const specialName = event.target.value;
      if (validateCurrentSpecialName(setSpecialNameError, specialName)) {
        setCurrentFavouriteProduct((old) => ({
          ...old,
          specialName: specialName,
        }));
      }
    },
    [setCurrentFavouriteProduct, setSpecialNameError]
  );

  return {
    validateFields,
    onAutocompleteChange,
    onAutocompleteReset,
    onResetCurrentProduct,
    goToLastPage,
    filterFavouriteProducts,
    searching,
    onSpecialNameInputChange,
  };
}

export function useSetContent(
  searchValue: string | undefined,
  favouriteProducts: any[],
  setTableContent: Dispatch<SetStateAction<any[]>>,
  filterFavouriteProducts: (item: FavouriteProduct) => boolean,
  pageNumber: number,
  pageSize: number
) {
  useEffect(() => {
    // search and paging
    if (searchValue) {
      setTableContent(favouriteProducts.filter(filterFavouriteProducts));
    } else {
      setTableContent(
        favouriteProducts.slice(
          pageNumber * pageSize,
          pageNumber * pageSize + pageSize
        )
      );
    }
  }, [
    favouriteProducts,
    searchValue,
    pageNumber,
    filterFavouriteProducts,
    setTableContent,
    pageSize,
  ]);
}

export function useFilterNotAddedFavouriteProducts(
  products: Product[],
  setAutocompleteOptions: Dispatch<SetStateAction<Product[]>>,
  favouriteProducts: any[]
) {
  const favouriteProductsExternalIds = useCallback(
    () => favouriteProducts.map((item) => item.product.externalProductId),
    [favouriteProducts]
  );

  useEffect(() => {
    // show only not already added products to table as options in autocomplete
    if (products.length > 0) {
      setAutocompleteOptions(
        products.filter(
          (item) =>
            !favouriteProductsExternalIds().includes(item.externalProductId)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favouriteProducts, products.length]);
}
