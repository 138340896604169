import React, { useState } from "react";

import { Button, Typography } from "@tiller-ds/core";

import { FormikProps } from "formik/dist/types";
import { useNavigate, useParams } from "react-router-dom";

import PageContainer from "../components/common/PageContainer";
import PageTitle from "../components/common/PageTitle";
import UpdateRestaurantForm, {
  UpdateRestaurantFormType,
} from "../components/restaurantEditForm/UpdateRestaurantForm";
import {
  useGetRestaurantDetails,
  useGetWarehouses,
} from "../hooks/restaurantHooks";
import { PageTitleType } from "../types/common";
import { Partner } from "../types/restaurantRegistrationTypes";

export default function RestaurantEditPage() {
  const [formikRef, setFormikRef] = useState<
    React.RefObject<FormikProps<UpdateRestaurantFormType>>
  >();
  const [warehouses, setWarehouses] = useState<Partner[]>([]);
  const [
    updateRestaurantFormInitialValues,
    setUpdateRestaurantFormInitialValues,
  ] = useState<UpdateRestaurantFormType>({} as UpdateRestaurantFormType);
  const [basicData, setBasicData] = useState<PageTitleType>();

  const navigate = useNavigate();
  const { restaurantId } = useParams();

  useGetWarehouses(setWarehouses);
  useGetRestaurantDetails(
    Number(restaurantId),
    setUpdateRestaurantFormInitialValues,
    setBasicData
  );

  return (
    <PageContainer>
      <PageTitle onBack={() => navigate(`/restaurant/${restaurantId}`)}>
        <div className="flex flex-col">
          <>{basicData?.title}</>
          <Typography className="font-medium text-xs">
            {basicData?.subtitle}
          </Typography>
        </div>
      </PageTitle>
      <UpdateRestaurantForm
        setFormikRef={setFormikRef}
        restaurantId={Number(restaurantId)}
        initialValues={updateRestaurantFormInitialValues}
        warehouses={warehouses}
      />
      <div className="flex justify-between py-4">
        <Button
          className="gap-2"
          variant="text"
          onClick={() => {
            navigate(`/restaurant/${restaurantId}`);
          }}
        >
          Odustani
        </Button>
        <Button
          className="gap-2"
          type="submit"
          onClick={() => formikRef?.current?.submitForm()}
        >
          Spremi
        </Button>
      </div>
    </PageContainer>
  );
}
