import React, { useState } from "react";

import { useModal, useNotificationContext } from "@tiller-ds/alert";
import { Button, Typography } from "@tiller-ds/core";

import { useNavigate, useParams } from "react-router-dom";

import { deleteRestaurant } from "../api/deleteRestaurant";
import UpdateFavouriteProductsSearch from "../components/addingFavouriteProductsForm/UpdateFavouriteProductsSearch";
import DeleteModal from "../components/common/DeleteModal";
import PageContainer from "../components/common/PageContainer";
import PageTitle from "../components/common/PageTitle";
import UpdateRestaurantPreferencesForm from "../components/restaurantPreferencesForm/UpdateRestaurantPreferencesForm";
import { useGetRestaurant } from "../hooks/restaurantHooks";
import { useMobileView } from "../hooks/useMobileView";
import { PageTitleType } from "../types/common";
import { RestaurantPreferencesFormType } from "../types/restaurantPreferencesTypes";
import { UpdatingFavouriteProduct } from "../types/updatingFavouriteProductsTypes";
import {
  createErrorNotification,
  createSuccessNotification,
} from "../util/functions";

export default function RestaurantOverviewPage() {
  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const [pageTitle, setPageTitle] = useState<PageTitleType>();
  const [favouriteProducts, setFavouriteProducts] = useState<
    UpdatingFavouriteProduct[]
  >([]);
  const [
    restaurantPreferencesFormValues,
    setRestaurantPreferencesFormValues,
  ] = useState<RestaurantPreferencesFormType>();
  const [mobileView, setMobileView] = useState(false);

  useGetRestaurant(
    Number(restaurantId),
    setPageTitle,
    setFavouriteProducts,
    setRestaurantPreferencesFormValues
  );

  const deleteModal = useModal();
  const { push } = useNotificationContext();

  useMobileView(setMobileView);

  const onConfirmDeleteRestaurant = () =>
    deleteRestaurant(Number(restaurantId)).then((response) => {
      if (response.status !== 200) {
        push(
          createErrorNotification(
            "Dogodila se greška tijekom brisanja restorana. Pokušajte ponovno."
          )
        );
      } else {
        push(createSuccessNotification("Restoran je uspješno izbrisan"));
        navigate("/restaurant");
      }
    });

  return (
    <PageContainer>
      <PageTitle
        onBack={() => navigate("/")}
        actions={
          <div className={mobileView ? "pb-4" : "space-x-4"}>
            <Button variant="text" color="danger" onClick={deleteModal.onOpen}>
              Izbriši restoran
            </Button>
            <Button onClick={() => navigate("edit")}>Uredi restoran</Button>
          </div>
        }
      >
        <div className="flex flex-col">
          <>{pageTitle?.title}</>
          <Typography className="font-medium text-xs">
            {pageTitle?.subtitle}
          </Typography>
        </div>
      </PageTitle>
      <div className="grid gap-y-4">
        {restaurantId && (
          <UpdateFavouriteProductsSearch
            initialFavouriteProducts={favouriteProducts}
            restaurantId={Number(restaurantId)}
          />
        )}
        {restaurantPreferencesFormValues && restaurantId && (
          <UpdateRestaurantPreferencesForm
            initialValues={restaurantPreferencesFormValues}
            restaurantId={Number(restaurantId)}
          />
        )}
      </div>
      {
        <DeleteModal
          modal={deleteModal}
          title="Izbriši restoran"
          content="Jeste li sigurni da želite izbrisati restoran?"
          buttonConfirmText="Izbriši restoran"
          buttonConfirmFunction={onConfirmDeleteRestaurant}
        />
      }
    </PageContainer>
  );
}
