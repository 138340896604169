import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import { useNotificationContext } from "@tiller-ds/alert";
import { FormLayout } from "@tiller-ds/form-elements";
import { RadioGroupField, ToggleField } from "@tiller-ds/formik-elements";

import { Formik, useFormikContext } from "formik";

import { updateRestaurant } from "../../api/updateRestaurant";
import { useMobileView } from "../../hooks/useMobileView";
import {
  PaymentMethod,
  RestaurantPreferencesFormType,
} from "../../types/restaurantPreferencesTypes";
import { createErrorNotification } from "../../util/functions";
import { PAYMENT_METHOD_VALIDATION_SCHEMA } from "../../validation/validationSchema";
import FormContainer from "../common/FormContainer";
import FormTitle from "../common/FormTitle";

type AutoSubmitProps = {
  setError: Dispatch<SetStateAction<boolean>>;
};

export type RestaurantRegistrationPreferencesFormProps = {
  initialValues: RestaurantPreferencesFormType;
  restaurantId: number;
};
export default function UpdateRestaurantPreferencesForm({
  initialValues,
  restaurantId,
}: RestaurantRegistrationPreferencesFormProps) {
  const { push } = useNotificationContext();
  const [error, setError] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  useEffect(() => {
    if (error) {
      push(
        createErrorNotification(
          "Dogodila se greška tijekom ažuriranja postavki. Pokušajte ponovno."
        )
      );
      setTimeout(() => setError(false), 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const AutoSubmit = ({ setError }: AutoSubmitProps) => {
    type FormValues = {
      showPrices: boolean;
      paymentMethod: PaymentMethod;
    };
    const { values } = useFormikContext<FormValues>();

    const initialRender = useRef(true);

    useEffect(() => {
      if (initialRender.current) {
        initialRender.current = false;
        return;
      }
      updateRestaurant(restaurantId, {
        preferences: {
          hidePrices: !values.showPrices,
          paymentMethod: values.paymentMethod,
        },
      }).then((response) => {
        if (response.status !== 200) {
          setError(true);
        }
      });
    }, [setError, values]);
    return null;
  };

  const preferencesSegment = () => (
    <>
      <FormTitle>Postavke</FormTitle>
      <div className="justify-start py-3">
        <ToggleField
          className="text-sm font-normal"
          name="showPrices"
          label={<p>Prikaži cijene artikala na mobilnoj aplikaciji</p>}
        />
      </div>
      <RadioGroupField
        className="text-sm font-medium"
        label="Preferirani način plaćanja"
        name="paymentMethod"
        vertical={mobileView}
      >
        <RadioGroupField.Item label="Gotovinsko" value="CASH" />
        <RadioGroupField.Item label="Kartično" value="CARD" />
        <RadioGroupField.Item label="Virmansko" value="BANK_TRANSFER" />
      </RadioGroupField>
    </>
  );

  return (
    <FormContainer>
      <Formik
        initialValues={{ ...initialValues }}
        onSubmit={() => {}}
        validationSchema={PAYMENT_METHOD_VALIDATION_SCHEMA}
      >
        {() => (
          <form autoComplete="off" className="flex flex-col gap-2">
            <FormLayout>{preferencesSegment()}</FormLayout>
            {error || <AutoSubmit setError={setError} />}
          </form>
        )}
      </Formik>
    </FormContainer>
  );
}
