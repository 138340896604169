import React, { useRef, useState } from "react";

import { Alert } from "@tiller-ds/alert";

import { FormikProps } from "formik/dist/types";
import { useNavigate, useSearchParams } from "react-router-dom";

import { login, LoginRequest } from "../api/login";
import PageContainer from "../components/common/PageContainer";
import LoginForm from "../components/login/LoginForm";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  REDIRECT_TO_QUERY_PARAM,
} from "../util/constants";

type LoginResponse = {
  accessToken: string;
};

export default function LoginPage() {
  const formikRef = useRef<FormikProps<any>>(null);

  const [failedLogin, setFailedLogin] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const redirectTo = searchParams.get(REDIRECT_TO_QUERY_PARAM) || "/";

  const handleSubmit = (values: LoginRequest) => {
    login(values).then((response) => {
      if (response.status === 200) {
        setFailedLogin(false);
        response.json().then((body: LoginResponse) => {
          localStorage.setItem(
            LOCAL_STORAGE_ACCESS_TOKEN_KEY,
            body.accessToken
          );
          navigate(redirectTo);
        });
      } else {
        setFailedLogin(true);
        formikRef?.current?.setSubmitting(false);
      }
    });
  };

  return (
    <PageContainer center>
      <LoginForm handleSubmit={handleSubmit} formikRef={formikRef}>
        {failedLogin && (
          <div className="m-3">
            <Alert variant="danger" className="justify-center">
              <>Neispravno korisničko ime ili lozinka</>
            </Alert>
          </div>
        )}
      </LoginForm>
    </PageContainer>
  );
}
