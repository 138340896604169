import React, { useState } from "react";

import { PasswordInputField } from "@tiller-ds/formik-elements";

import LabeledField from "./LabeledField";
import { useMobileView } from "../../hooks/useMobileView";

type LabeledInputFieldProps = {
  label: string;
  fieldName: string;
};
export default function LabeledPasswordField({
  label,
  fieldName,
}: LabeledInputFieldProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  return (
    <>
      {mobileView ? (
        <PasswordInputField
          label={label}
          name={fieldName}
          className="col-span-full"
        />
      ) : (
        <LabeledField label={label}>
          <PasswordInputField name={fieldName} className="col-span-4" />
        </LabeledField>
      )}
    </>
  );
}
