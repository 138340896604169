import { API_PREFIX } from "../util/constants";

export type AddFavouriteProductRequest = {
  externalProductId: string;
  originalName: string;
  specialName?: string;
};

export async function addFavouriteProduct(
  restaurantId: number,
  request: AddFavouriteProductRequest
): Promise<Response> {
  return await fetch(`${API_PREFIX}/restaurant/${restaurantId}/product`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
