import React, { useState } from "react";

import { InputField } from "@tiller-ds/formik-elements";

import LabeledField from "./LabeledField";
import { useMobileView } from "../../hooks/useMobileView";

type LabeledInputFieldProps = {
  label: string;
  fieldName: string;
  addOn?: string;
  disabled?: boolean;
};
export default function LabeledInputField({
  label,
  fieldName,
  addOn,
  disabled,
}: LabeledInputFieldProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  const inputField = (
    <InputField
      addOn={addOn}
      name={fieldName}
      disabled={disabled}
      className={mobileView ? "col-span-full" : "col-span-4"}
      label={mobileView ? label : undefined}
    />
  );
  return (
    <>
      {mobileView ? (
        inputField
      ) : (
        <LabeledField label={label}>{inputField}</LabeledField>
      )}
    </>
  );
}
