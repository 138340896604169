export const API_PREFIX = "/api/backoffice";

export const PHONE_NUMBER_PREFIX = "+385";

export const TOKEN_PREFIX = "Bearer ";

export const LOCAL_STORAGE_ACCESS_TOKEN_KEY = "access_token";

export const REDIRECT_TO_QUERY_PARAM = "redirectTo";

export const REQUIRED_FIELD_ERROR = "Polje je obvezno";

export const MAX_SMALL_SCREEN_SIZE = 640;

export const MAX_AUTOCOMPLETE_ITEM_LENGTH_MOBILE_VIEW = 28;

export const LOGIN_PATH = "/login";
