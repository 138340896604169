import React, { useEffect, useState } from "react";

import { IconButton } from "@tiller-ds/core";
import { Icon } from "@tiller-ds/icons";

import { useNavigate } from "react-router-dom";

import logo from "../../assets/img/marikomerc-logo.svg";
import { useMobileView } from "../../hooks/useMobileView";
import {
  LOCAL_STORAGE_ACCESS_TOKEN_KEY,
  LOGIN_PATH,
} from "../../util/constants";

export type SidebarProps = {
  children: React.ReactNode;
};
export default function Sidebar({ children }: SidebarProps) {
  const [mobileView, setMobileView] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showMobileMenu, setShowMobileMenu] = useState(true);

  const isLoginPage = () => window.location.pathname === LOGIN_PATH;

  useMobileView(setMobileView);

  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN_KEY);
    navigate(LOGIN_PATH);
  };

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setShowMobileMenu(false);
        } else {
          setShowMobileMenu(mobileView);
        }
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY, mobileView]);

  return (
    <>
      {isLoginPage() ? (
        <>{children}</>
      ) : (
        <>
          <div
            className={
              mobileView
                ? `flex items-center justify-between transition-transform duration-300 transform ${
                    showMobileMenu ? "translate-y-0" : "-translate-y-full"
                  }`
                : "flex flex-col bg-white text-white h-screen w-16 fixed"
            }
          >
            <img
              src={logo}
              alt="logo"
              className={mobileView ? "h-10 w-10 mx-5" : "px-4 py-6"}
            />
            <main className="mb-auto" />
            <IconButton
              className={`flex items-end justify-center ${
                mobileView ? "mx-4" : "py-6"
              }`}
              icon={
                <Icon
                  type="sign-in"
                  size={mobileView ? 5 : 7}
                  className="text-slate-600"
                />
              }
              title="Odjavi se"
              showTooltip={false}
              onClick={logout}
            />
          </div>
          <div className={mobileView ? "" : "flex-1 ml-16"}>
            <main>{children}</main>
          </div>
        </>
      )}
    </>
  );
}
