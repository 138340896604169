import { PaymentMethod } from "../types/restaurantPreferencesTypes";
import { API_PREFIX } from "../util/constants";

export type PartnerRequest = {
  id: string;
  name: string;
};

export type RegisterRestaurantRequest = {
  restaurant: {
    id: string;
    name: string;
    address: string;
    hidePrices: boolean;
    paymentMethod: PaymentMethod;
  };
  client: PartnerRequest;
  userAccount: {
    username: string;
    password: string;
  };
  headOfSupply: {
    firstName: string;
    lastName: string;
    contactNumber: string;
  };
  warehouse: PartnerRequest;
};

export type RegisteredRestaurantResponse = {
  id: number;
};

export type ErrorResponse = {
  additionalInfo: { notValidField: string };
};

export async function registerRestaurant(
  request: RegisterRestaurantRequest
): Promise<Response> {
  return await fetch(`${API_PREFIX}/restaurant`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(request),
  });
}
