import React, { useState } from "react";

import { useMobileView } from "../../hooks/useMobileView";

export type PageContainerProps = {
  children: React.ReactNode;
  center?: boolean;
};
export default function PageContainer({
  center,
  children,
}: PageContainerProps) {
  const [mobileView, setMobileView] = useState(false);
  useMobileView(setMobileView);

  return (
    <div
      className={`bg-slate-100 min-h-screen ${
        center && !mobileView ? "py-52" : "p-6"
      }`}
    >
      {children}
    </div>
  );
}
