import React from "react";

type FormContainerProps = {
  children: React.ReactNode;
  className?: string;
};
export default function FormContainer({
  children,
  className,
}: FormContainerProps) {
  return (
    <div
      className={`border rounded-lg .gap-16 p-6 bg-white ${className ?? ""}`}
    >
      {children}
    </div>
  );
}
