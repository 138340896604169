import { Dispatch, SetStateAction } from "react";

import { Product } from "../../types/addingFavouriteProductsTypes";
import { REQUIRED_FIELD_ERROR } from "../../util/constants";
import {
  maxCharacterCountMessage,
  notValidSpecialNameRegex,
} from "../../validation/validationSchema";

export type CurrentFavouriteProduct = {
  product?: Product;
  specialName?: string;
};

const MAXIMUM_SPECIAL_NAME_LENGTH = 20;
const SPECIAL_NAME_TOO_LONG_ERROR = `Alias ne smije sadržavati više od ${MAXIMUM_SPECIAL_NAME_LENGTH} znakova`;
const INSERTED_FORBIDDEN_CHAR = "Uneseni znak nije dozvoljen";

const MAX_SEARCH_LENGTH = 30;

export const validateCurrentSpecialName = (
  setError: Dispatch<SetStateAction<string | undefined>>,
  specialName?: string
) => {
  if (specialName) {
    if (notValidSpecialNameRegex.test(specialName)) {
      setError(INSERTED_FORBIDDEN_CHAR);
      return false;
    }
    if (specialName.length > MAXIMUM_SPECIAL_NAME_LENGTH) {
      setError(SPECIAL_NAME_TOO_LONG_ERROR);
      return false;
    }
  }
  setError("");
  return true;
};

export const validateCurrentProduct = (
  currentProduct: Product | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>
) => {
  const valid = !!currentProduct;
  if (valid) {
    setError("");
  } else {
    setError(REQUIRED_FIELD_ERROR);
  }
  return valid;
};

export const validateSearchInput = (
  searchValueLength: number,
  setSearchInputError: Dispatch<SetStateAction<string | undefined>>
) => {
  if (searchValueLength > MAX_SEARCH_LENGTH) {
    setSearchInputError(maxCharacterCountMessage(MAX_SEARCH_LENGTH));
    return false;
  }
  setSearchInputError(undefined);
  return true;
};
